import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import PageSetup from '../main/page-setup'
import { ContentSection, Row, Grid } from '../common/layout'

import MinhasAulasCursoList from '../minhas-aulas/minhas-aulas-curso-list'
import MeuCursoShow from './meu-curso-show'

import { obter } from './meus-cursos-action'
import { listar as listar_aulas} from '../minhas-aulas/minhas-aulas-action'
import MeuCursoAvaliacoes from './meu-curso-avaliacoes'

class MeuCursoInfo extends Component {

    componentDidMount() {
        const { match: { params } } = this.props;
        this.props.obter(params['id_inscricao'])
        this.props.listar_aulas( {'id_inscricao': params['id_inscricao']} )
    }

    render() {
        return (
            <PageSetup title='Capa do Curso'>
            <ContentSection>
                <Row>
                    <Grid cols="md-6">
                        <MeuCursoShow/>
                        <MeuCursoAvaliacoes avaliacoes={this.props.meus_cursos?.dados?.avaliacoes}/>
                    </Grid>
                    <Grid cols="md-6">
                        <MinhasAulasCursoList onClickEditModulo={()=>{}}/>
                    </Grid>
                </Row>
            </ContentSection>
            
            </PageSetup>
        )
    }
}

const mapStateToProps = state => ({
    meus_cursos: state.meusCursos,
})
const mapDispatchToProps = dispatch => bindActionCreators({obter, listar_aulas}, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(MeuCursoInfo)

