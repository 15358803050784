import React from 'react'
import { BoxWidget, BoxFooterComments, BoxComments } from '../common/widget/box'
import { LabelDanger, LabelInfo } from '../common/ui/labels';

export default function MeuCursoAvaliacoes(props) {

    function render_avaliacao() {
        console.log(props.avaliacoes)
        return (props.avaliacoes || []).map(au =>
            <BoxComments key={'aula' + au['id_aula']}>
                {au['titulo']} &nbsp;

                {(parseInt(au['tipo']) > 19) && <i style={{ color: '#f56954' }} className="fa fa-registered margin-r-5"></i>}

                {/15|16|35/.test(au?.tipo) &&
                   <i style={{ color: (au['tipo'] === '16' ? '#39CCCC' : '#f39c12') }} className="fa fa-question-circle pull-right margin-r-5"></i>
                }
                {/14/.test(au?.tipo) &&
                    <i style={{ color: '#f39c12' }} className="fa fa-cloud-upload pull-right margin-r-5"></i>
                }
                {/-1/.test(au?.tipo) &&
                    <i style={{ color: '#f39c12' }} className="fa fa-diamond pull-right margin-r-5"></i>
                }

                {(parseInt(au['peso']) > 0) 
                    ?<LabelInfo title={'Peso | ' + au['peso']} addClass="pull-right" />
                    :<LabelDanger title={'Peso | ' + au['peso']} addClass="pull-right" />
                }
            </BoxComments>
        )
    }

    return (
        <BoxWidget
            key={'avaliacoes-'}
            collapsed={false}
            title="Avaliações do Curso"
            subtitle="">
            <BoxFooterComments>
                {render_avaliacao()}
            </BoxFooterComments>
        </BoxWidget>
    )
}
