import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { decodeBase64 } from '../sys/base64'
import config from '../main/config'

import { BoxWidget, BoxBody } from '../common/widget/box'
import { BigButtonWarning } from '../common/ui'

import { prep_inscricao } from './proximo-curso-action'


class ProximoCursoShow extends Component {

    constructor(props) {
        super(props)
        this.preparar_inscricao = this.preparar_inscricao.bind(this)
    }

    preparar_inscricao(id_curso, id_grade_item) {
        this.props.prep_inscricao(id_curso, id_grade_item)
    }

    render() {
        const dados = this.props.proximo_curso.dados
        const pode_fazer_inscricao = 
            dados?.pre_requisito?.pode_fazer === 'S'
            && dados?.tipo_matricula === '10'

        const cdn_endpoint = this.props.login.usuario['cdn_endpoint']
        const img_defa = config().CDN_PATH + '/curso.png'
        const img = (dados['image_file']) ? (cdn_endpoint + dados['image_file']) : (img_defa)

        return (
            <BoxWidget
                title={dados['titulo']}
                subtitle={dados['subtitulo']}>
                <BoxBody>
                    <img className="img-responsive pad" src={img} alt="Curso" />

                    {pode_fazer_inscricao
                        && <BigButtonWarning
                            align_class="btn-lg pull-right"
                            onClick={(e) => { this.preparar_inscricao(dados['id_curso'], dados['id_grade_item']) }}
                        >
                            Fazer minha Inscrição
                        </BigButtonWarning>
                    }

                    <h4><b>Visão Geral</b></h4>
                    <div dangerouslySetInnerHTML={{ __html: decodeBase64(dados['visao_geral']) }}></div>
                    <br /><h4><b>Pré-Requistos</b></h4>
                    <div dangerouslySetInnerHTML={{ __html: decodeBase64(dados['pre_requisitos']) }}></div>
                    {dados?.pre_requisito?.pode_fazer === 'N'
                        && <h4>Pendente: {dados?.pre_requisito?.disciplina}</h4>
                    }
                    {dados['media'] && (<><br /><h4><b>Nota: {dados['media']}</b></h4></>)}
                </BoxBody>
            </BoxWidget>
        )
    }

}

const mapStateToProps = state => ({
    proximo_curso: state.proximoCurso,
    login: state.login
})
const mapDispatchToProps = dispatch => bindActionCreators({ prep_inscricao }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(ProximoCursoShow)

