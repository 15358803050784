import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { decodeBase64 } from '../sys/base64'
import config from '../main/config'
import { date_loc } from '../sys/normalize-location'

import { BoxWidget, BoxBody } from '../common/widget/box'
//import { ProgressBarAqua } from '../common/ui/progress-bar'
import { BadgeRed, BadgeYellow } from '../common/ui/badge'
import { meuCursoSituacao } from './meu-curso-situacao'
import ProgressBar from '../common/widget/progress-bar'


class CursoShow extends Component {

    render_prazo(dados) {
        if (dados?.expirado === '1')
            return (
                <>
                    <BadgeRed>Prazo: {date_loc(dados?.dt_prazo)}</BadgeRed>
                    <BadgeRed>Curso Expirado</BadgeRed>
                </>
            )

        if (dados?.dt_prazo && (parseInt(dados?.faltam_dias) <= 7))
            return (
                <>
                    <BadgeRed>{dados?.faltam_dias} dias</BadgeRed>
                    <BadgeRed>Prazo: {date_loc(dados?.dt_prazo)}</BadgeRed>

                </>
            )

        if (dados?.dt_prazo)
            return (
                <>
                    <BadgeYellow>{dados?.faltam_dias} dias</BadgeYellow>
                    <BadgeYellow>Prazo: {date_loc(dados?.dt_prazo)}</BadgeYellow>
                </>
            )
    }

    render_avaliacao_manual(dados) {
        if (dados['aval_manual'])
            return (
                <>
                    <br /><h4><b>Avaliações</b></h4>
                    <ul>
                        {dados['aval_1'] && <li>{dados['aval_1']} (peso {dados['aval_1_peso']})</li>}
                        {dados['aval_2'] && <li>{dados['aval_2']} (peso {dados['aval_2_peso']})</li>}
                        {dados['aval_3'] && <li>{dados['aval_3']} (peso {dados['aval_3_peso']})</li>}
                        {dados['aval_4'] && <li>{dados['aval_4']} (peso {dados['aval_4_peso']})</li>}
                        {dados['aval_r'] &&
                            <li>
                                {dados['aval_r']} ( {
                                    dados['aval_r_peso'] === 9 ? 'Sobrepõe Média' : 'peso ' + dados['aval_r_peso']
                                })
                            </li>}
                    </ul>
                </>
            )
    }


    render() {
        const dados = this.props.meus_cursos.dados
        const sit_curso = meuCursoSituacao(this.props.meus_cursos.dados['sit_inscricao'])

        const cdn_endpoint = this.props.login.usuario['cdn_endpoint']
        const img_defa = config().CDN_PATH + '/curso.png'
        const img = (dados['image_file']) ? (cdn_endpoint + dados['image_file']) : (img_defa)

        return (
            <BoxWidget
                title={this.props.meus_cursos.dados['titulo']}
                subtitle={this.props.meus_cursos.dados['subtitulo']}>
                <BoxBody>
                    {sit_curso}
                    {this.render_prazo(dados)}

                    {/* <BadgeAqua title={this.props.meus_cursos.dados['sit_inscricao_label']} /> */}
                    <img className="img-responsive pad" src={img} alt="Curso" />
                    {/* <ProgressBarAqua value={this.props.meus_cursos.dados['per_aulas']} label='Andamento' /> */}
                    {/* <ProgressBarAqua value={this.props.meus_cursos.dados['aproveitamento']} label='Aproveitamento' /> */}

                    <h4><b>Visão Geral</b></h4>
                    <div dangerouslySetInnerHTML={{ __html: decodeBase64(this.props.meus_cursos.dados['visao_geral']) }}></div>
                    {/*
                    <br/><h4><b>Descrição</b></h4>
                    <div dangerouslySetInnerHTML={{__html: decodeBase64(this.props.meus_cursos.dados['descricao']) }}></div>
                     
                    */}
                    <br /><h4><b>Pré-Requistos</b></h4>
                    <div dangerouslySetInnerHTML={{ __html: decodeBase64(this.props.meus_cursos.dados['pre_requisitos']) }}></div>
                    {this.props.meus_cursos.dados['media'] && (<><br /><h4><b>Nota: {this.props.meus_cursos.dados['media']}</b></h4></>)}
                    {/*this.render_avaliacao_manual(this.props.meus_cursos.dados)*/}

                    {this.props.meus_cursos.dados['sit_inscricao'] === "1" &&
                        <ProgressBar percent={this.props.meus_cursos.dados['per_aulas']} />
                    }

                </BoxBody>
            </BoxWidget>
        )
    }

}

const mapStateToProps = state => ({
    meus_cursos: state.meusCursos,
    login: state.login
})
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(CursoShow)

