import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { validar_upload } from './minhas-aulas-action'
import Modal, { modal_close } from '../common/ui/modal'
import { FileUpload } from '../common/ui/fileupload'
import { GoFileBucketUpload } from '../sys/transmitir'

class MinhaAulaTarefaAdd extends Component {

    constructor(props) {
        super(props);
        this.state = { texto: '', peso: '0', tamanho: 0 }
        this.handleChange = this.handleChange.bind(this)
        this.handle_salvar_arquivo = this.handle_salvar_arquivo.bind(this)
    }

    handleChange(event) {
        this.setState({ texto: event.target.value, tamanho: event.target.value.length });
    }

    handle_salvar_arquivo(data, filename) {
        const dados = this.props.minhas_aulas.dados
        const params = { texto: this.state.texto, filename, id_inscricao: dados['id_inscricao'], id_aula: dados['id_aula'] }
        GoFileBucketUpload(params, data, 'meu-curso-aula-url-upload', (dados) => (this.props.validar_upload(dados['id_inscricao'], dados['id_aula'])))
        modal_close('minha-aula-tarefa-add')
    }

    render() {
        return (
            <form>
                <Modal
                    id='minha-aula-tarefa-add'
                    title='Tarefa'
                    confirmLabel='Confirmar'
                    confirmButtonType='hide'
                    onConfirm={() => { return true }}
                    onClose={() => { return true }}
                >
                    <div className="form-group">
                        <p>Descreva sua entrega:</p>
                        <textarea className="form-control"
                            value={this.state.texto}
                            onChange={this.handleChange}
                            rows={4}
                            maxLength={2000}
                        />
                    </div>
                    <div className="clearfix"></div>
                    Tamanho: {this.state['tamanho']}/2000

                    <FileUpload btn_class="info pull-right" label='Enviar Arquivo PDF da Tarefa'
                        mimeType={[
                            'application/pdf'
                        ]}
                        maxFileSize={35}
                        afterSelectFile={(data, filename) => { this.handle_salvar_arquivo(data, filename) }}
                    />

                </Modal>
            </form>
        )
    }
}

const mapStateToProps = state => ({
    minhas_aulas: state.minhasAulas,
    login: state.login
})
const mapDispatchToProps = dispatch => bindActionCreators({ validar_upload }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(MinhaAulaTarefaAdd)

