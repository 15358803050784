import React from 'react'
import { Link } from 'react-router-dom'

export default props => (
    <div className={`${props.col_class || ''}`}>
      <div className="box box-widget widget-user">
        <div className="widget-user-header bg-black" style={{backgroundImage: `url('${props.image}')`, backgroundPosition: 'center center'}}>
        <h3 className="widget-user-username">Elizabeth Pierce</h3>
        <h5 className="widget-user-desc">Web Designer</h5>
        </div>
        <div className="widget-user-image">
              <img class="img-circle" src="../dist/img/user3-128x128.jpg" alt="User Avatar" />
            </div>
        <div className="box-footer" style={{minHeight: props.minHeightContent || '170px'}}>

          { props.route && 
              <Link to={props.route}>
                  <h4 className="widget-user-username">{props.title || ''}</h4>
              </Link> }

          { !props.route && 
            <h4 className="widget-user-username">{props.title || ''}</h4>
          }
          
          <h5 className="widget-user-desc">{props.subtitle || ''}</h5>
          {  props.text && <p>{props.text}</p>  }
          {  props.textHTML && <div dangerouslySetInnerHTML={{__html: props.textHTML || ''}}></div> }
          { props.children }
        </div>
      </div>
    </div>
)



