import React, { Component } from 'react'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { date_time_loc } from '../sys/normalize-location'

import { BoxGroup, BoxWidget, BoxBody, BoxFooterComments, BoxComments, BoxFooter } from '../common/widget/box'
import { Icon } from '../common/ui'
import { LabelDanger, LabelInfo } from '../common/ui/labels'

import { modal_open } from '../common/ui/modal'
import { set_pergunta, like, atribuir_pontos } from './minhas-aulas-action'
import config from '../main/config'
import { GoFileBucketDownload } from '../sys/transmitir'
import { Anchor } from '../common/helper'

class MinhaAulaTarefa extends Component {

    tem_acesso(acesso) {
        let classe = (this.props.login.usuario['classe'] || '').split(',')
        for (let i in classe) {
            if (acesso.includes(classe[i])) {
                return true
            }
        }
        return false
    }

    handle_download_arquivo(id_pergunta) {
        const dados = this.props.minhas_aulas.dados
        const params = {
            id_inscricao: dados['id_inscricao'],
            id_aula: dados['id_aula'],
            id_pergunta: id_pergunta
        }
        GoFileBucketDownload(params, 'meu-curso-aula-url-download')
    }

    render_uma_pergunta(pergunta) {
        const img_perfil_defa = config().IMAGE_PATH + 'profile.jpg'
        const cdn_endpoint = this.props.login.usuario['cdn_endpoint']
        const img_perfil = (pergunta['foto_file']) ? (cdn_endpoint + pergunta['foto_file']) : (img_perfil_defa)
        const esta_ativa = pergunta?.sit_pergunta === '1'

        return (
            <React.Fragment key={`per-fra-${pergunta['id_pergunta']}`}>
                <BoxFooter />
                <BoxGroup key={`per-bg-${pergunta['id_pergunta']}`}
                    image={img_perfil}
                    title={(pergunta['professor'] && pergunta['professor'] === '1' ? 'Prof. ' : ' ') + pergunta['nome']}
                    description={date_time_loc(pergunta['dh_inc'])}
                />
                <BoxBody key={`per-bb-${pergunta['id_pergunta']}`}>
                    {(parseFloat(pergunta?.peso) > 0) &&
                        <h5><Icon icon="fa fa-diamond text-aqua" />
                            <LabelInfo title={`peso ${pergunta?.peso}`} />
                            {(parseFloat(pergunta?.pontos) > 0) && <LabelInfo title={`nota ${pergunta?.pontos}`} />}
                            {!esta_ativa && <LabelDanger title='Cancelado' />}
                        </h5>
                    }
                    <p style={{ color: (esta_ativa ? '': '#999') ,  textDecoration: (esta_ativa ? '' : 'line-through') }}>{pergunta['texto']}</p>
                    {pergunta?.url_download && <h5><Anchor href={pergunta?.url_download} target="_blank" >Baixar Arquivo da Tarefa</Anchor></h5>}

                    <button type="button" className="btn btn-default btn-xs"
                        onClick={() => { this.props.set_pergunta(pergunta['id_pergunta']); modal_open('minha-aula-resposta-add') }}
                    ><i className="fa fa-commenting-o"></i> Comentar</button>
                    
                    {/*(pergunta['url_download']) &&
                        <button type="button" className="btn btn-default btn-xs"
                            onClick={() => {
                                this.handle_download_arquivo(pergunta['id_pergunta']);
                            }}
                        ><i className="fa fa-cloud-download"></i> Arquivo Anexo</button>
                    */}

                    {(pergunta['eu_mesmo'] !== '1') &&
                        <button type="button" className="btn btn-default btn-xs"
                            onClick={() => {
                                this.props.like(
                                    this.props.minhas_aulas.dados['id_inscricao'],
                                    this.props.minhas_aulas.dados['id_aula'],
                                    pergunta['id_pergunta'])
                            }}
                        ><i className={`fa fa-thumbs-${pergunta['meu_like'] === '1' ? 'up text-aqua' : 'o-up'}`}></i> Like</button>
                    }
                    <span className="pull-right text-muted">{pergunta['qt_like']} likes - {pergunta['qt_comentarios']} comentários</span>

                </BoxBody>
            </React.Fragment>
        )
    }

    render_estrelas(pontos) {
        if (pontos === 2) return (<i className='fa fa-star-o' />)
        if (pontos === 4) return (<><i className='fa fa-star-o' /><i className='fa fa-star-o' /></>)
        if (pontos === 6) return (<><i className='fa fa-star'></i><i className='fa fa-star' /><i className='fa fa-star-half-empty' /></>)
        if (pontos === 8) return (<><i className='fa fa-star'></i><i className='fa  fa-star' /><i className='fa  fa-star' /><i className='fa  fa-star' /></>)
        if (pontos === 10) return (<><i className='fa fa-star'></i><i className='fa fa-star' /><i className='fa fa-star' /><i className='fa  fa-star' /><i className='fa fa-star' /></>)
    }

    render_botoes_pontos(resposta) {
        return (
            <>
                <button type="button" className="btn btn-info btn-xs" title="Insuficiente"
                    onClick={() => {
                        this.props.atribuir_pontos(
                            this.props.minhas_aulas.dados['id_inscricao'],
                            this.props.minhas_aulas.dados['id_aula'],
                            resposta['id_pergunta'], 2)
                    }}
                >{this.render_estrelas(2)}</button>

                <button type="button" className="btn btn-info btn-xs" title="Fraco"
                    onClick={() => {
                        this.props.atribuir_pontos(
                            this.props.minhas_aulas.dados['id_inscricao'],
                            this.props.minhas_aulas.dados['id_aula'],
                            resposta['id_pergunta'], 4)
                    }}
                >{this.render_estrelas(4)}</button>

                <button type="button" className="btn btn-info btn-xs" title="Regular"
                    onClick={() => {
                        this.props.atribuir_pontos(
                            this.props.minhas_aulas.dados['id_inscricao'],
                            this.props.minhas_aulas.dados['id_aula'],
                            resposta['id_pergunta'], 6)
                    }}
                >{this.render_estrelas(6)}</button>

                <button type="button" className="btn btn-info btn-xs" title="Bom"
                    onClick={() => {
                        this.props.atribuir_pontos(
                            this.props.minhas_aulas.dados['id_inscricao'],
                            this.props.minhas_aulas.dados['id_aula'],
                            resposta['id_pergunta'], 8)
                    }}
                >{this.render_estrelas(8)}</button>

                <button type="button" className="btn btn-info btn-xs" title="Excelente"
                    onClick={() => {
                        this.props.atribuir_pontos(
                            this.props.minhas_aulas.dados['id_inscricao'],
                            this.props.minhas_aulas.dados['id_aula'],
                            resposta['id_pergunta'], 10)
                    }}
                >{this.render_estrelas(10)}</button>
            </>
        )
    }

    render_uma_resposta(resposta) {
        const img_perfil_defa = config().IMAGE_PATH + 'profile.jpg'
        const cdn_endpoint = this.props.login.usuario['cdn_endpoint']
        const img_perfil = (resposta['foto_file']) ? (cdn_endpoint + resposta['foto_file']) : (img_perfil_defa)

        return (
            <BoxFooterComments key={`res-co-${resposta['id_pergunta']}`}>
                <BoxComments
                    title={(resposta['professor'] && resposta['professor'] === '1' ? 'Prof. ' : ' ') + resposta['nome']}
                    image={img_perfil}
                    right={date_time_loc(resposta['dh_inc'])}
                >
                    {(parseInt(resposta['pontos']) > 0) &&
                        <h5><LabelInfo>{this.render_estrelas(parseInt(resposta['pontos']))}</LabelInfo></h5>
                    }

                    <p>{resposta['texto']}</p>

                    {(resposta['eu_mesmo'] !== '1') &&
                        <button type="button" className="btn btn-default btn-xs" style={{ marginRight: 15 }}
                            onClick={() => {
                                this.props.like(
                                    this.props.minhas_aulas.dados['id_inscricao'],
                                    this.props.minhas_aulas.dados['id_aula'],
                                    resposta['id_pergunta'])
                            }}
                        ><i className={`fa fa-thumbs-${resposta['meu_like'] === '1' ? 'up text-aqua' : 'o-up'}`}></i> Like</button>
                    }

                    {(parseFloat(resposta['peso']) > 0) &&
                        this.tem_acesso('professor,tutor') &&
                        this.render_botoes_pontos(resposta)}

                    <span className="pull-right text-muted">{resposta['qt_like']} likes</span>

                </BoxComments>
            </BoxFooterComments>
        )
    }


    render_perguntas() {
        const perguntas = (this.props.minhas_aulas['perguntas'] || []).filter(e => e.proposito === '2')

        if (perguntas.length < 1) {
            return (
                <BoxBody>
                    <p>Ainda não temos tarefas entregues por aqui.</p>
                </BoxBody>
            )
        }

        return perguntas.map((pergunta) => {
            if (pergunta['tipo'] === '1') {
                return this.render_uma_pergunta(pergunta)
            } else {
                return this.render_uma_resposta(pergunta)
            }
        })
    }

    render() {
        return (
            <BoxWidget title="Entrega de Tarefa">
                <BoxBody>
                    <button type="button" className="btn pull-right" onClick={() => { modal_open('minha-aula-tarefa-add') }} >Entregar Arquivo da Tarefa <i className="fa fa-cloud-upload"></i></button>
                </BoxBody>
                {this.render_perguntas()}

            </BoxWidget>
        )
    }

}

const mapStateToProps = state => ({
    minhas_aulas: state.minhasAulas,
    login: state.login
})
const mapDispatchToProps = dispatch => bindActionCreators({ set_pergunta, like, atribuir_pontos }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(MinhaAulaTarefa)

