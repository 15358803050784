import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { Row } from '../common/layout'
import CardImageTop from '../common/widget/card-image-top'
import ProgressBar from '../common/widget/progress-bar'
import { BadgeRed, BadgeYellow } from '../common/ui/badge'
import { date_loc } from '../sys/normalize-location'


import config from '../main/config'
import { listar } from './meus-cursos-action'
import { meuCursoSituacao } from './meu-curso-situacao'
import CardSecond, { Item } from '../common/widget/card-second'
class MeusCursosLista extends Component {

    componentDidMount() {
        this.props.listar([])
    }

    render_prazo(dados) {
        if (dados?.expirado === '1')
            return (
                <>
                    <BadgeRed>Prazo: {date_loc(dados?.dt_prazo)}</BadgeRed>
                    <BadgeRed>Curso Expirado</BadgeRed>
                </>
            )

        if (dados?.dt_prazo && (parseInt(dados?.faltam_dias) <= 7))
            return (
                <>
                    <BadgeRed>{dados?.faltam_dias} dias</BadgeRed>
                    <BadgeRed>Prazo: {date_loc(dados?.dt_prazo)}</BadgeRed>

                </>
            )

        if (dados?.dt_prazo)
            return (
                <>
                    <BadgeYellow>{dados?.faltam_dias} dias</BadgeYellow>
                    <BadgeYellow>Prazo: {date_loc(dados?.dt_prazo)}</BadgeYellow>
                </>
            )
    }


    renderCursosx(dados) {
        const cdn_endpoint = this.props.login.usuario['cdn_endpoint']
        const img_defa = config().CDN_PATH + '/curso.png'

        return dados.map(vo => {
            const img = (vo['image_file']) ? (cdn_endpoint + vo['image_file']) : (img_defa)
            return (
                <CardImageTop
                    key={'inscr' + vo['id_inscricao']}
                    col_className="col-md-4"
                    minHeightContent={'200px'}
                    route={`/meu-curso-info/${vo['id_inscricao']}`}
                    image={img}
                    color_className={'bg-aqua-active'}
                    title={vo['titulo']}
                >
                    {vo['sit_inscricao'] === "1" &&
                        <ProgressBar percent={vo['per_aula']} />
                    }
                    {this.render_prazo(vo)}
                    {meuCursoSituacao(vo['sit_inscricao'])}
                    {vo['media'] && (<><br /><h4><b>Nota: {vo['media']}</b></h4></>)}

                </CardImageTop>
            )
        })
    }

    renderCursos(dados) {
        const cdn_endpoint = this.props.login.usuario['cdn_endpoint']
        const img_defa = config().CDN_PATH + '/curso.png'

        return dados.map(vo => {
            const img = (vo['image_file']) ? (cdn_endpoint + vo['image_file']) : (img_defa)
            return (
                <CardSecond
                    key={'inscr' + vo['id_inscricao']}
                    title={vo['titulo']}
                    image={img}
                    col_class="col-md-3"
                    color_class='bg-yellow'
                    minHeightHeader={'110px'}
                    route={`/meu-curso-info/${vo['id_inscricao']}`}
                >
                    <Item text="Progresso">
                        <BadgeYellow>{vo['per_aula']}%</BadgeYellow> 
                    </Item>
                    <Item text="Situação">
                        {meuCursoSituacao(vo['sit_inscricao'])}
                    </Item>
                    <Item text="Prazo">
                        {this.render_prazo(vo)}
                    </Item>
                    <Item text="Nota">
                        {vo['media'] && <BadgeYellow>{vo['media']}</BadgeYellow> }
                    </Item>
                </CardSecond>
            )
        })
    }


    render_view() {
        return (
            <Row>
                {this.renderCursos(this.props.meus_cursos.lista)}
            </Row>
        )
    }

    render() {
        return this.render_view()
    }

}

const mapStateToProps = state => ({
    meus_cursos: state.meusCursos,
    login: state.login
})
const mapDispatchToProps = dispatch => bindActionCreators({ listar }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(MeusCursosLista)

